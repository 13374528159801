import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from 'emilus/constants/ThemeConstant';
import { AppLocaleKey } from 'lang';
import { useDispatch, useSelector } from 'react-redux';
import { MiroState } from './store';

export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSED_NAV';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SIDE_NAV_STYLE_CHANGE = 'SIDE_NAV_STYLE_CHANGE';
export const NAV_TYPE_CHANGE = 'NAV_TYPE_CHANGE';
export const TOP_NAV_COLOR_CHANGE = 'TOP_NAV_COLOR_CHANGE';
export const HEADER_NAV_COLOR_CHANGE = 'HEADER_NAV_COLOR_CHANGE';
export const TOGGLE_MOBILE_NAV = 'TOGGLE_MOBILE_NAV';
export const SWITCH_THEME = 'SWITCH_THEME';

export type ThemeState = {
  navCollapsed: boolean;
  sideNavTheme: string;
  locale: AppLocaleKey;
  navType: string;
  topNavColor: string;
  headerNavColor: string;
  mobileNav: boolean;
  currentTheme: string;
};

const defaultTheme: ThemeState = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '',
  mobileNav: false,
  currentTheme: 'light',
};

export function getSavedTheme(): ThemeState {
  const json = localStorage.getItem('miro.theme');
  return json ? JSON.parse(json) : defaultTheme;
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState: getSavedTheme(),
  reducers: {
    updateTheme: (state, action: PayloadAction<Partial<ThemeState>>) => {
      return { ...state, ...action.payload } as ThemeState;
    },
  },
});

export default themeSlice.reducer;

const { updateTheme } = themeSlice.actions;

export function useTheme(): ThemeState {
  return useSelector((state: MiroState) => state.theme);
}

export function useUpdateTheme() {
  const dispatch = useDispatch();
  return (updates: Partial<ThemeState>) => {
    dispatch(updateTheme(updates));
  };
}
