import React from 'react';
import { store } from 'data/store';
import { Provider } from 'react-redux';
import AppTheme from 'AppTheme';
import Views from './views';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <AppTheme>
          <Views />
        </AppTheme>
      </Provider>
    </div>
  );
}

export default App;
