import { Grid, Layout } from 'antd';
import navigationConfig from 'AppNavigation';
import { useTheme } from 'data/theme';
import Footer from 'emilus/components/layout/Footer';
import HeaderNav from 'emilus/components/layout/HeaderNav';
import MobileNav from 'emilus/components/layout/MobileNav';
import PageHeader from 'emilus/components/layout/PageHeader';
import SideNav from 'emilus/components/layout/SideNav';
import TopNav from 'emilus/components/layout/TopNav';
import Loading from 'emilus/components/shared/Loading';
import {
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from 'emilus/constants/ThemeConstant';
import utils from 'emilus/utils';
import React from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useLocation } from 'react-router-dom';
import AppViews from 'views/app';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export default function AppLayout() {
  const { pathname } = useLocation();
  const { navCollapsed, navType } = useTheme();
  const currentRouteInfo = utils.getRouteInfo(navigationConfig, pathname);

  const isMobile = !useBreakpoint().lg;
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  if (status === 'loading') {
    return <Loading cover="page" />;
  }

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} />
      {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
      <Layout className="app-container">
        {isNavSide && !isMobile ? (
          <SideNav routeInfo={currentRouteInfo} />
        ) : null}
        <Layout
          className="app-layout"
          style={{ paddingLeft: getLayoutGutter() }}
        >
          <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
            />
            <Content>
              <AppViews />
            </Content>
          </div>
          <Footer />
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
}
