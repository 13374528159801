import {
  LogoutOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import utils from 'emilus/utils';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

interface NavItemProps {
  Icon: React.ComponentType<any>;
  label: string;
  action: string | (() => void);
}

function NavItem(props: NavItemProps) {
  const { Icon, label, action } = props;
  return typeof action === 'string' ? (
    <Link to={action}>
      <Icon className="mr-3" />
      <span>{label}</span>
    </Link>
  ) : (
    <div onClick={action}>
      <Icon className="mr-3" />
      <span>{label}</span>
    </div>
  );
}

export default function NavProfile(props: {
  user: { firstName: string; lastName: string };
  organization?: { name: string };
  onSignOut: () => void;
}) {
  const { user, organization, onSignOut } = props;

  const navItems: NavItemProps[] = useMemo(
    () => [
      { label: 'Account settings', Icon: UserOutlined, action: '/app/profile' },
      { label: 'Help', Icon: QuestionCircleOutlined, action: '/auth/help' },
      { label: 'Sign out', Icon: LogoutOutlined, action: onSignOut },
    ],
    [onSignOut]
  );

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={45} style={{ backgroundColor: '#e25822' }}>
            <div style={{ marginTop: 1 }}>{utils.getNameInitial(user)}</div>
          </Avatar>
          <div className="pl-3">
            <h4 className="mb-0">
              {user.firstName} {user.lastName}
            </h4>
            <span className="text-muted">{organization?.name}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu selectable={false} className="font-weight-normal">
          {navItems.map((item, index) => (
            <Menu.Item>
              <NavItem {...item} key={index} />
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </div>
  );
  return (
    <Menu className="d-flex align-item-center" mode="horizontal">
      <Menu.Item>
        <Dropdown
          placement="bottomRight"
          overlay={profileMenu}
          trigger={['click']}
        >
          <Avatar
            size={32}
            style={{
              fontSize: 14,
              marginTop: -8,
              backgroundColor: '#e25822',
            }}
          >
            <div style={{ marginTop: 1 }}>{utils.getNameInitial(user)}</div>
          </Avatar>
        </Dropdown>
      </Menu.Item>
    </Menu>
  );
}
