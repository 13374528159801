import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { api } from './api';
import { MiroState } from './store';
import { AuthUser } from './types';

export type AuthState = {
  user: AuthUser;
  token: string;
};

export type Login = {
  email: string;
  password: string;
};

const noAuth: AuthState = {
  user: {
    id: '0',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    members: [],
  },
  token: '',
};

export function getSavedAuth(): AuthState {
  const json = localStorage.getItem('miro.auth');
  return json ? JSON.parse(json) : noAuth;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: getSavedAuth(),
  reducers: {},
  extraReducers: (builder) => {
    const { login, logout } = api.endpoints;
    builder
      .addMatcher(login.matchFulfilled, (_, { payload }) => payload)
      .addMatcher(logout.matchFulfilled, () => noAuth)
      .addDefaultCase(() => {});
  },
});

export default authSlice.reducer;

export function useAuth() {
  return useSelector((state: MiroState) => state.auth);
}

export function useAuthUserRequired() {
  const { user, token } = useAuth();
  const { replace } = useHistory();
  if (!token) {
    replace('/auth/login');
  }
  return user;
}
