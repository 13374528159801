import { ArrowLeftOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import { useTheme, useUpdateTheme } from 'data/theme';
import Flex from 'emilus/components/shared/Flex';
import { NAV_TYPE_SIDE } from 'emilus/constants/ThemeConstant';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Logo from './Logo';
import MenuContent from './MenuContent';

export default function MobileNav({
  routeInfo,
  hideGroupTitle,
  localization = true,
}) {
  const { sideNavTheme, mobileNav } = useTheme();
  const updateTheme = useUpdateTheme();
  const onClose = () => updateTheme({ mobileNav: false });

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo={true} />
          <div className="nav-close" onClick={onClose}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <Scrollbars autoHide>
            <MenuContent
              type={NAV_TYPE_SIDE}
              {...{ sideNavTheme, routeInfo, hideGroupTitle, localization }}
            />
          </Scrollbars>
        </div>
      </Flex>
    </Drawer>
  );
}
