import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { ThunkMiddleware } from 'redux-thunk';
import { api } from './api';
import authReducer from './auth';
import themeReducer from './theme';

const authReset: ThunkMiddleware = (store) => (next) => (action) => {
  next(action);
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    store.dispatch(api.endpoints.logout.initiate());
  }
};

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    theme: themeReducer,
  },
  middleware: (m) => m().concat(api.middleware, authReset),
});

setupListeners(store.dispatch);

store.subscribe(() => {
  const { auth, theme } = store.getState();
  localStorage.setItem('miro.auth', JSON.stringify(auth));
  localStorage.setItem('miro.theme', JSON.stringify(theme));
});

export type MiroState = ReturnType<typeof store.getState>;
