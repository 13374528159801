import { CopyOutlined } from '@ant-design/icons';
import { Button, message, Radio, Switch } from 'antd';
import { useUpdateTheme, useTheme } from 'data/theme';
import ColorPicker from 'emilus/components/shared/ColorPicker';
import {
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  SIDE_NAV_DARK,
  SIDE_NAV_LIGHT,
} from 'emilus/constants/ThemeConstant';
import utils from 'emilus/utils/index';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import NavLanguage from './NavLanguage';

const colorOptions = ['#3e82f7', '#24a772', '#de4436', '#924aca', '#193550'];

const ListOption = ({ name, selector, disabled = false, vertical = false }) => (
  <div
    className={`my-4 ${
      vertical ? '' : 'd-flex align-items-center justify-content-between'
    }`}
  >
    <div
      className={`${disabled ? 'opacity-0-3' : ''} ${vertical ? 'mb-3' : ''}`}
    >
      {name}
    </div>
    <div>{selector}</div>
  </div>
);

export default function ThemeConfigurator() {
  const {
    navType,
    sideNavTheme,
    navCollapsed,
    topNavColor,
    headerNavColor,
    locale,
    currentTheme,
  } = useTheme();
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const isCollapse = navCollapsed;

  const { switcher, themes } = useThemeSwitcher();
  const updateTheme = useUpdateTheme();

  const toggleTheme = (isChecked) => {
    const changedTheme = isChecked ? 'dark' : 'light';
    switcher({ theme: themes[changedTheme] });
    updateTheme({ currentTheme: changedTheme, headerNavColor: '' });
  };

  const ontopNavColorClick = (value) => {
    const { rgb } = value;
    const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
    const hex = utils.rgbaToHex(rgba);
    updateTheme({ topNavColor: hex, headerNavColor: '' });
  };
  const onHeaderNavColorClick = (value) => {
    const { rgb } = value;
    const rgba = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`;
    updateTheme({ headerNavColor: utils.rgbaToHex(rgba) });
  };

  const onNavTypeClick = (value) => {
    updateTheme({
      navType: value,
      headerNavColor: '',
    });
    if (value === NAV_TYPE_TOP) {
      updateTheme({
        topNavColor: colorOptions[0],
        navCollapsed: false,
      });
    }
  };

  const genCopySettingJson = (configState) =>
    JSON.stringify(configState, null, 2);

  return (
    <>
      <div className="mb-5">
        <h4 className="mb-3 font-weight-bold">Navigation</h4>
        {isNavTop ? (
          <ListOption
            name="Top Nav Color:"
            vertical
            selector={
              <ColorPicker
                color={topNavColor}
                colorChange={ontopNavColorClick}
              />
            }
          />
        ) : (
          <ListOption
            name="Header Nav Color:"
            vertical
            selector={
              <ColorPicker
                color={headerNavColor}
                colorChange={onHeaderNavColorClick}
              />
            }
          />
        )}

        <ListOption
          name="Navigation Type:"
          selector={
            <Radio.Group
              size="small"
              onChange={(e) => onNavTypeClick(e.target.value)}
              value={navType}
            >
              <Radio.Button value={NAV_TYPE_SIDE}>Side</Radio.Button>
              <Radio.Button value={NAV_TYPE_TOP}>Top</Radio.Button>
            </Radio.Group>
          }
        />
        <ListOption
          name="Side Nav Color:"
          selector={
            <Radio.Group
              disabled={isNavTop}
              size="small"
              onChange={(e) => updateTheme({ sideNavTheme: e.target.value })}
              value={sideNavTheme}
            >
              <Radio.Button value={SIDE_NAV_LIGHT}>Light</Radio.Button>
              <Radio.Button value={SIDE_NAV_DARK}>Dark</Radio.Button>
            </Radio.Group>
          }
          disabled={isNavTop}
        />
        <ListOption
          name="Side Nav Collapse:"
          selector={
            <Switch
              disabled={isNavTop}
              checked={isCollapse}
              onChange={() => updateTheme({ navCollapsed: !navCollapsed })}
            />
          }
          disabled={isNavTop}
        />
        <ListOption
          name="Dark Theme:"
          selector={
            <Switch checked={currentTheme === 'dark'} onChange={toggleTheme} />
          }
        />
      </div>
      <div className="mb-5">
        <h4 className="mb-3 font-weight-bold">Locale</h4>
        <ListOption name="Language:" selector={<NavLanguage configDisplay />} />
      </div>
      <div>
        <CopyToClipboard
          text={genCopySettingJson({
            navType,
            sideNavTheme,
            navCollapsed,
            topNavColor,
            headerNavColor,
            locale,
            currentTheme,
          })}
          onCopy={() =>
            message.success(
              'Copy Success, please paste it to src/configs/AppConfig.js THEME_CONFIG variable.'
            )
          }
        >
          <Button icon={<CopyOutlined />} block>
            <span>Copy Setting</span>
          </Button>
        </CopyToClipboard>
      </div>
    </>
  );
}
