import {
  AlertOutlined,
  BarChartOutlined,
  CheckCircleOutlined,
  ClusterOutlined,
  DashboardOutlined,
  DollarOutlined,
  FileProtectOutlined,
  FundOutlined,
  SisternodeOutlined,
  SolutionOutlined,
} from '@ant-design/icons';

export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

const APP = APP_PREFIX_PATH;

const networkNavTree = [
  {
    key: 'network',
    path: `${APP}/network`,
    title: 'Network Management',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'alarm',
        path: `${APP}/alarms`,
        title: 'Alarms',
        icon: AlertOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'inventory',
        path: `${APP}/inventory`,
        title: 'Inventory',
        icon: ClusterOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'discovery',
        path: `${APP}/discovery`,
        title: 'Discovery',
        icon: SisternodeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'reports',
        path: `${APP}/reports`,
        title: 'Reports',
        icon: FileProtectOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const ticketsNavTree = [
  {
    key: 'tickets',
    title: 'Request Handling',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'tickets-dashboard',
        path: `${APP}/tickets/dashboard`,
        title: 'Overview',
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'tickets-home',
        path: `${APP}/tickets`,
        title: 'Tickets',
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'tickets-report',
        path: `${APP}/tickets/report`,
        title: 'Report',
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const organizationNavTree = [
  {
    key: 'organization',
    title: 'Organization',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'billing',
        path: `${APP}/billing`,
        title: 'Billing',
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'uat',
        path: `${APP}/uat`,
        title: 'UAT',
        icon: CheckCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...networkNavTree,
  ...ticketsNavTree,
  ...organizationNavTree,
];

export default navigationConfig;
