import React, { memo } from 'react';

type Props = {
  type: React.ComponentType;
  style?: React.CSSProperties;
  className?: string;
};

export default memo(function Icon({ type, className, style }: Props) {
  return React.createElement<any>(type, { className, style });
});
