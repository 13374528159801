import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { useLogoutMutation } from 'data/api';
import { useAuthUserRequired } from 'data/auth';
import { useTheme, useUpdateTheme } from 'data/theme';
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from 'emilus/constants/ThemeConstant';
import utils from 'emilus/utils';
import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import NavNotification from './NavNotification';
import NavPanel from './NavPanel';
import NavProfile from './NavProfile';
import NavSearch from './NavSearch';

const { Header } = Layout;

export default function HeaderNav({ isMobile = false }) {
  const user = useAuthUserRequired();
  const [logout] = useLogoutMutation();
  const {
    currentTheme,
    headerNavColor,
    navCollapsed,
    navType,
    mobileNav,
  } = useTheme();
  const updateTheme = useUpdateTheme();
  const [searchActive, setSearchActive] = useState(false);

  const organization = user.members && user.members[0]?.organization;

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      updateTheme({ navCollapsed: !navCollapsed });
    } else {
      updateTheme({ mobileNav: !mobileNav });
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === 'dark' ? '#00000' : '#ffffff'
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
        <Logo logoType={navMode} />
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={onToggle}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
            </ul>
          </div>
          <div className="nav-right">
            <NavNotification />
            {/* <NavLanguage /> */}
            <NavProfile
              user={user}
              organization={organization}
              onSignOut={logout}
            />
            <NavPanel />
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
}
