import { SettingOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import React, { useState } from 'react';
import ThemeConfigurator from './ThemeConfigurator';

export default function NavPanel() {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item onClick={() => setVisible(true)}>
          <SettingOutlined className="nav-icon mr-0" />
        </Menu.Item>
      </Menu>
      <Drawer
        title="Theme Config"
        placement="right"
        width={350}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <ThemeConfigurator />
      </Drawer>
    </>
  );
}
