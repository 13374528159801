import { APP_PREFIX_PATH as APP } from 'AppNavigation';
import { useAuthUserRequired } from 'data/auth';
import Loading from 'emilus/components/shared/Loading';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

export const AppViews = () => {
  useAuthUserRequired();
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP}/tickets`}
          component={lazy(() => import('./tickets'))}
        />
        <Route
          path={`${APP}/alarms`}
          component={lazy(() => import('./alarms'))}
        />
        <Route path={`${APP}/uat`} component={lazy(() => import('./uat'))} />
        <Route
          path={`${APP}/support`}
          component={lazy(() => import('./support'))}
        />
        <Route
          path={`${APP}/profile`}
          component={lazy(() => import('./profile'))}
        />
        <Route
          path={`${APP}/inventory`}
          component={lazy(() => import('./inventory'))}
        />
        <Route
          path={`${APP}/discovery`}
          component={lazy(() => import('./discovery'))}
        />
        <Route
          path={`${APP}/reports`}
          component={lazy(() => import('./reports/ReportArchives'))}
        />
        <Route
          path={`${APP}/billing`}
          component={lazy(() => import('./billing/Billing'))}
        />
        <Redirect exact from={`${APP}`} to={`${APP}/alarms`} />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
