import { ConfigProvider } from 'antd';
import { useTheme } from 'data/theme';
import AppLocale from 'lang';
import React from 'react';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { IntlProvider } from 'react-intl';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

type Props = {
  children: React.ReactNode;
};

export default function AppTheme({ children }: Props) {
  const theme = useTheme();
  const { locale, messages, antd } = AppLocale[theme.locale];
  return (
    <ThemeSwitcherProvider
      themeMap={themes}
      defaultTheme={theme.currentTheme}
      insertionPoint="styles-insertion-point"
    >
      <IntlProvider locale={locale} messages={messages}>
        <ConfigProvider locale={antd} children={children} />
      </IntlProvider>
    </ThemeSwitcherProvider>
  );
}
