import {
  createApi,
  fetchBaseQuery,
  skipToken,
} from '@reduxjs/toolkit/query/react';
import { useMemo } from 'react';
import { AuthState, Login } from './auth';
import { MiroState } from './store';
import {
  Alarm,
  DataOptions,
  DataSeries,
  Device,
  DiscoveryJob,
  FileName,
  HealthInfo,
  Id,
  Job,
  Measurement,
  Monitor,
  Network,
  Tag,
  Report,
  Download,
  JobResult,
  JobResultWithData,
  Payment,
  BillingPlan,
  Subscription,
  Ticket,
  TicketsFilter,
  NewTicket,
  UpdateTicket,
  User,
  PasswordChange,
} from './types';

const apiUrl = process.env.REACT_APP_API_URL;

function mutationQuery<Body>(url: string, method = 'POST') {
  return (body: Body) => ({ url, method, body });
}

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as MiroState;
      const token = state.auth.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),

  tagTypes: ['Tickets', 'Network', 'Billing', 'Users'],

  endpoints: ({ query, mutation }) => ({
    login: mutation<AuthState, Login>({
      query: mutationQuery('auth/login'),
      invalidatesTags: ['Tickets', 'Network'],
    }),

    logout: mutation<unknown, void>({
      // this endpoint does not make an actual request to the API request
      // it simply triggers clearing of the API and authentication state
      queryFn: () => ({ data: true }),
      invalidatesTags: ['Tickets', 'Network'],
    }),

    changePassword: mutation<PasswordChange, void>({
      query: mutationQuery('/auth/password'),
    }),

    // NETWORKS

    listNetworks: query<Network[], void>({
      query: () => 'networks',
      providesTags: ['Network'],
    }),

    getNetwork: query<Network, Id>({
      query: (id) => `networks/${id}`,
      providesTags: ['Network'],
    }),

    listDevices: query<Device[], Id>({
      query: (id) => `networks/${id}/devices`,
      providesTags: ['Network'],
    }),

    listMeasurements: query<Measurement[], Id>({
      query: (id) => `networks/${id}/measurements`,
      providesTags: ['Network'],
    }),

    listMonitors: query<Monitor[], Id>({
      query: (netId) => `networks/${netId}/monitors`,
      providesTags: ['Network'],
    }),

    listAlarms: query<Alarm[], Id>({
      query: (id) => `networks/${id}/alarms`,
      providesTags: ['Network'],
    }),

    getDevice: query<Device, Id>({
      query: (id) => `devices/${id}`,
      providesTags: ['Network'],
    }),

    listDeviceMeasurements: query<Measurement[], Id>({
      query: (id) => `devices/${id}/measurements`,
      providesTags: ['Network'],
    }),

    listDeviceMonitors: query<Monitor[], Id>({
      query: (id) => `devices/${id}/monitors`,
      providesTags: ['Network'],
    }),

    listDeviceAlarms: query<Alarm[], Id>({
      query: (id) => `devices/${id}/alarms`,
      providesTags: ['Network'],
    }),

    listDeviceHealth: query<HealthInfo[], Id>({
      query: (id) => `devices/${id}/health`,
      providesTags: ['Network'],
    }),

    listDeviceTags: query<Tag[], Id>({
      query: (id) => `devices/${id}/tags`,
      providesTags: ['Network'],
    }),

    listMeasurementMetrics: query<Measurement[], Id>({
      query: (id) => `measurements/${id}/metrics`,
      providesTags: ['Network'],
    }),

    getMeasurementData: query<DataSeries[], DataOptions>({
      query: ({ measurementId, metricKey }) => ({
        url: `measurements/${measurementId}/data`,
        params: { metrics: metricKey },
      }),
      providesTags: ['Network'],
    }),

    // DISCOVERY

    listDiscoveryJobs: query<Job[], Id>({
      query: (networkId) => `networks/${networkId}/discovery/jobs`,
      providesTags: ['Network'],
    }),

    listDiscoveryFiles: query<FileName[], Id>({
      query: (networkId) => `networks/${networkId}/discovery/files`,
      providesTags: ['Network'],
    }),

    getDiscoveryJob: query<DiscoveryJob, Id>({
      query: (jobId) => `discovery/jobs/${jobId}`,
      providesTags: ['Network'],
    }),

    getDiscoveryFile: query<string, FileName>({
      query: (fileName) => `discovery/files/${fileName}`,
      providesTags: ['Network'],
    }),

    listDiscoveryResults: query<JobResult[], Id>({
      query: (id) => `discovery/jobs/${id}/results`,
      providesTags: ['Network'],
    }),

    getDiscoveryResult: query<JobResultWithData, Id>({
      query: (id) => `discovery/results/${id}`,
      providesTags: ['Network'],
    }),

    // REPORTS
    listReports: query<Report[], Id>({
      query: (networkId) => `networks/${networkId}/reports`,
      providesTags: ['Network'],
    }),

    downloadReport: mutation<Download, Id>({
      query: (id) => ({
        url: `reports/${id}`,
        responseHandler: async (res): Promise<Download> => {
          // there should be a content disposition header with the file name
          // if not, use the ID as the name, though this should never happen
          const disp = res.headers.get('content-disposition');
          const name = disp ? disp.split('"')[1] : id.toString();
          const objectUrl = URL.createObjectURL(await res.blob());

          return { name, objectUrl };
        },
      }),
    }),

    // BILLING

    listPayments: query<Payment[], void>({
      query: () => 'billing/payments',
      providesTags: ['Billing'],
    }),

    listPlans: query<BillingPlan, void>({
      query: () => 'billing/plans',
      providesTags: ['Billing'],
    }),

    getSubscription: query<Subscription, void>({
      query: () => 'billing/subscription',
      providesTags: ['Billing'],
    }),

    initiatePayment: mutation<{ paymentUrl: string }, { redirect: string }>({
      query: mutationQuery('billing/pay/initiate'),
    }),

    completePayment: mutation<{ status: string }, { transactionId: Id }>({
      query: mutationQuery('billing/pay/complete'),
      invalidatesTags: ['Billing'],
    }),

    // TICKETS

    listAllTickets: query<Ticket[], void>({
      query: () => 'tickets',
      providesTags: ['Tickets'],
    }),

    listTickets: query<Ticket[], TicketsFilter>({
      query: (params) => ({ url: 'tickets', params }),
      providesTags: ['Tickets'],
    }),

    getTicket: query<Ticket, Id>({
      query: (id) => `tickets/${id}`,
      providesTags: ['Tickets'],
    }),

    createTicket: mutation<Ticket, NewTicket>({
      query: mutationQuery('tickets'),
      invalidatesTags: ['Tickets'],
    }),

    updateTicket: mutation<Ticket, UpdateTicket>({
      query: (body) => ({ url: `tickets/${body.id}`, method: 'POST', body }),
      invalidatesTags: ['Tickets'],
    }),

    // USERS

    listMembers: query<User[], void>({
      query: () => 'members',
      providesTags: ['Users'],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useChangePasswordMutation,

  useListNetworksQuery,
  useGetNetworkQuery,

  useListDevicesQuery,
  useGetDeviceQuery,

  useListMeasurementsQuery,
  useListMonitorsQuery,
  useListAlarmsQuery,

  useListDeviceMeasurementsQuery,
  useListDeviceMonitorsQuery,
  useListDeviceAlarmsQuery,
  useListDeviceHealthQuery,
  useListDeviceTagsQuery,

  useListMeasurementMetricsQuery,
  useGetMeasurementDataQuery,

  useListDiscoveryFilesQuery,
  useGetDiscoveryFileQuery,
  useListDiscoveryJobsQuery,
  useGetDiscoveryJobQuery,
  useListDiscoveryResultsQuery,
  useGetDiscoveryResultQuery,

  useListReportsQuery,
  useDownloadReportMutation,

  useGetSubscriptionQuery,
  useListPlansQuery,
  useListPaymentsQuery,
  useInitiatePaymentMutation,
  useCompletePaymentMutation,

  useListAllTicketsQuery,
  useListTicketsQuery,
  useGetTicketQuery,
  useCreateTicketMutation,
  useUpdateTicketMutation,

  useListMembersQuery,
} = api;

export function useNetwork() {
  const { data, error, isLoading } = useListNetworksQuery();
  const id = data ? data[0].id : skipToken;
  const name = data ? data[0].name : '';
  const description = '';
  const organizationId = data ? data[0].organizationId : '';
  return useMemo(
    () =>
      ({ id, name, description, organizationId, error, isLoading } as const),
    [description, error, id, isLoading, name, organizationId]
  );
}

export function downloadAttachment(id: Id) {
  return `${apiUrl}uploads/${id}`;
}
