import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'emilus/components/shared/Loading';
import { AUTH_PREFIX_PATH as AUTH } from 'AppNavigation';

export const AuthViews = () => {
  return (
    <div className="auth-container">
      <Suspense fallback={<Loading cover="page" />}>
        <Switch>
          <Route
            path={`${AUTH}/login`}
            component={lazy(() => import(`./routes/Login`))}
          />
          <Route
            path={`${AUTH}/register`}
            component={lazy(() => import(`./routes/Register`))}
          />
          <Route
            path={`${AUTH}/forgot-password`}
            component={lazy(() => import(`./routes/ForgotPassword`))}
          />
          <Route
            path={`${AUTH}/reset-password`}
            component={lazy(() => import(`./routes/ResetPassword`))}
          />
          <Route
            path={`${AUTH}/error`}
            component={lazy(() => import(`./routes/ErrorPage`))}
          />
          <Route
            path={`${AUTH}/help`}
            component={lazy(() => import(`./routes/Help`))}
          />
          <Redirect from={`${AUTH}`} to={`${AUTH}/login`} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default AuthViews;
