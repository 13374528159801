import enLang from './entries/en_US';
import zhLang from './entries/zh_CN';
import frLang from './entries/fr_FR';
import jaLang from './entries/ja_JP';

const AppLocale = {
  en: enLang,
  zh: zhLang,
  fr: frLang,
  ja: jaLang,
};

export type AppLocaleKey = keyof typeof AppLocale;

export default AppLocale;
