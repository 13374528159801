import React from 'react';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'AppNavigation';
import { useAuth } from 'data/auth';
import AppLayout from 'emilus/layouts/AppLayout';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AuthViews from './auth';

export default function Views() {
  const { token } = useAuth();

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to={token ? APP_PREFIX_PATH : AUTH_PREFIX_PATH} />
        </Route>
        <Route path={AUTH_PREFIX_PATH}>
          <AuthViews />
        </Route>
        <Route path={APP_PREFIX_PATH}>
          <AppLayout />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
